<template>
  <v-container>
    <v-card elevation="0" v-if="stato == '1'">
      <v-card-title><h2>Spedizione n° {{ spedizione }}</h2></v-card-title>
      <v-card-text class="mt-4">
        <v-row>
          <v-col cols="12" xs="12" sm="4" md="3"><h2>Destinatario:</h2></v-col>
          <v-col cols="12" xs="12" sm="4" md="3">
            <p class="subheading">{{ ragSoc }}</p>
            <p class="subheading">{{ indirizzo }}</p>
            <p class="subheading mb-0">
              {{ cap }} {{ citta }} ({{ provincia }})
            </p>
          </v-col>
          <v-col cols="12" xs="12" sm="4" md="7"></v-col>
        </v-row>
        <v-row class="mt-5">
          <v-col cols="12" xs="12" sm="4" md="3">
            <p class="subheading"><b>Colli a magazzino: {{ colliMag }}</b></p>
            <p class="subheading"><b>Colli indicati su DDT: {{ colliDDT }}</b></p>
          </v-col>
          <v-col cols="12" xs="12" sm="8" md="9"></v-col>
        </v-row>
        <GFFColliMagDDT :giacenza="giacenza" :colliMag="colliMag" :colliDDT="colliDDT" v-if="componente == '1'" />
        <GFFColliMinDDT :giacenza="giacenza" v-if="componente == '2'" />
      </v-card-text>
    </v-card>
    <v-layout justify-center>
      <v-dialog v-model="overlay" persistent max-width="600">
        <v-card>
          <v-card-text class="pt-5">
            <v-progress-linear
              indeterminate
              color="primary"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="giacStato" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">Attenzione</v-card-title>
          <v-card-text class="subheading">{{ message }} </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" depressed @click="close()">Esci</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>

    <v-layout justify-center>
      <v-dialog v-model="generalError" persistent max-width="530">
        <v-card>
          <v-card-title class="headline">Attenzione</v-card-title>
          <v-card-text class="subheading"
            >Il sistema non riesce a risolvere la chiamata ai servizi,
            contattare l'assistenza
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" depressed @click="close()">Esci</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>

<script>
import GFFColliMagDDT from "../components/GFFColliMagDDT";
import GFFColliMinDDT from "../components/GFFColliMinDDT";

export default {
  name: "GiacenzeGFF",
  components: {
    GFFColliMagDDT,
    GFFColliMinDDT,
  },
  data: () => ({
    overlay: false,
    colliDDT: "",
    colliMag: "",
    stato: "0",
    spedizione: "",
    ragSoc: "",
    indirizzo: "",
    cap: "",
    citta: "",
    provincia: "",
    giacenza: "1",
    giacStato: false,
    generalError: false,
    message: "",
    componente: "",
  }),
  mounted() {
    this.giacenza = this.$route.params.giacenza;
    this.overlay = true;
    this.axios
      .get(`${this.$store.state.servicePath}/api/Giac210f/${this.giacenza}`)
      .then((res) => {
        this.overlay = false;
        this.stato = res.data.stato;
        if (res.data.stato == "0") {
          this.message = "Giacenza non trovata.";
          this.giacStato = true;
        } else if (res.data.stato == "2") {
          this.message = "La giacenza ci risulta già stata gestita.";
          this.giacStato = true;
        } else {
          this.spedizione = res.data.bolla["blnbol"];
          this.ragSoc = res.data.bolla["blrsod"];
          this.indirizzo = res.data.bolla["blindd"];
          this.cap = res.data.bolla["blcapd"];
          this.citta = res.data.bolla["bllocd"];
          this.provincia = res.data.bolla["blprvd"];
          if (res.data.bolla["blnrco"] > res.data.giacenza["colliAMag"]) {
            this.componente = "1";
          } else {
            this.componente = "2";
          }
          this.colliDDT = res.data.bolla["blnrco"];
          this.colliMag = res.data.giacenza["colliAMag"];
        }
      })
      .catch(() => {
        this.overlay = false;
        this.generalError = true;
      });
  },
  methods: {
    close() {
      this.overlay = true;
      location.href = "https://www.arcospedizioni.it";
    },
  },
};
</script>