<template>
  <v-container>
    <v-layout v-if="$store.state.visualizzaSelectMaster">
      <v-flex xs12 md4>
        <v-select
          v-model="$store.state.MasterSelezionato"
          :items="$store.state.codiciDaVisualizzare"
          @change="CambiaCodiceSelezionato()"
          outlined
        ></v-select>
      </v-flex>
    </v-layout>
    <v-dialog v-model="documentDialog" persistent max-width="600">
      <v-card>
        <v-card-title class="headline">{{ $t("key599") }}</v-card-title>
        <v-card-text>{{ $t("key623") }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed color="primary" text @click="documentDialog = false"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="noSelection" persistent max-width="600">
      <v-card>
        <v-card-title class="headline">{{ $t("key599") }}</v-card-title>
        <v-card-text>{{ $t("key617") }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed color="primary" text @click="noSelection = false"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="alertDate" persistent max-width="600">
      <v-card>
        <v-card-title class="headline">{{ $t("key599") }}</v-card-title>
        <v-card-text
          >Gentile cliente, a partire dal 21 Novembre 2022 la struttura del csv
          è cambiata. Non è possibile scaricare fatture in modalità massiva
          includendo fatture precedenti e antecedenti questa data.<br />E'
          possibile scaricare le fatture selezionate in due tranches: fatture
          prima del 21/11/2022 e fatture successive al 22/11/2022.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed color="primary" text @click="alertDate = false"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="serviceError" persistent max-width="600">
      <v-card>
        <v-card-title class="headline">{{ $t("key599") }}</v-card-title>
        <v-card-text>{{ $t("key618") }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed color="primary" text @click="serviceError = false"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card>
      <v-layout justify-center>
        <v-dialog v-model="overlay" persistent max-width="600">
          <v-card>
            <v-card-text class="pt-5">
              <v-progress-linear
                indeterminate
                color="primary"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-layout>
      <v-form>
        <v-layout class="px-4 pt-4">
          <v-flex xs12 sm2 class="d-flex justify-center">
            <v-dialog
              ref="dialog"
              v-model="modal"
              :return-value.sync="data_partenza"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="data_partenza"
                  :label="$t('key035')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="it-IT"
                v-model="data_partenza"
                scrollable
                :min="minPartenza"
                :max="maxPartenza"
                @input="
                  $refs.dialog.save(data_partenza);
                  checkFinale();
                "
                color="primary"
                first-day-of-week="1"
              >
                <v-spacer></v-spacer>
                <v-btn
                  depressed
                  color="#A0A4A7"
                  style="color: white"
                  @click="modal = false"
                  >{{ $t("key335") }}</v-btn
                >
                <v-btn depressed color="primary" @click="checkFinale()"
                  >OK</v-btn
                >
              </v-date-picker>
            </v-dialog>
          </v-flex>
          <v-flex xs12 sm2 class="d-flex justify-center ml-4">
            <v-dialog
              ref="dialog2"
              v-model="modal2"
              :return-value.sync="data_finale"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="data_finale"
                  :label="$t('key036')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="it-IT"
                v-model="data_finale"
                scrollable
                :min="minFinale"
                :max="maxFinale"
                @input="$refs.dialog2.save(data_finale)"
                color="primary"
                first-day-of-week="1"
              >
                <v-spacer></v-spacer>
                <v-btn
                  depressed
                  color="#A0A4A7"
                  style="color: white"
                  @click="modal2 = false"
                  >{{ $t("key335") }}</v-btn
                >
                <v-btn depressed color="primary" @click="checkFinale()"
                  >OK</v-btn
                >
              </v-date-picker>
            </v-dialog>
          </v-flex>
          <v-flex xs12 sm1 class="d-flex justify-center">
            <v-btn
              @click="Cerca()"
              large
              style="box-shadow: none; color: white"
              color="primary"
              >{{ $t("key004") }}</v-btn
            >
          </v-flex>
          <v-flex xs12 sm2 class="d-flex justify-center">
            <v-btn
              @click="EstraiCsv()"
              large
              style="box-shadow: none; color: white"
              color="primary"
              >{{ $t("key619") }}</v-btn
            >
          </v-flex>
          <v-flex xs12 sm3 class="d-flex justify-center">
            <v-btn
              @click="EstraiCsvMassivi()"
              large
              style="box-shadow: none; color: white"
              color="primary"
              >{{ $t("key620") }}</v-btn
            >
          </v-flex>
          <v-flex xs0 sm1> </v-flex>
          <v-flex xs12 sm2 class="d-flex justify-center">
            <v-btn
              large
              style="box-shadow: none; color: white"
              color="primary"
              @click="DownloadManuale()"
            >
              MANUALE FATTURE</v-btn
            >
          </v-flex>
        </v-layout>
        <v-data-table
          :headers="tableTranslated"
          :items="fatture"
          :show-select="true"
          :single-select="singleSelect"
          v-model="selected"
          item-key="numero"
          :items-per-page="25"
          sort-by="dataOrdinamento"
          :sort-desc="true"
          :footer-props="{
            'items-per-page-options': [5, 10, 25, -1],
          }"
        >
          <template v-slot:[`item.pdf`]="{ item }">
            <v-tooltip top v-if="item.pdf.trim() !== ''">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-on="on"
                  @click="DownloadPdf(item.societa + '/' + item.pdf)"
                  v-blur
                  class="text-xs-center"
                >
                  mdi-cloud-download
                </v-icon>
              </template>
              <span>Download pdf</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.csv`]="{ item }">
            <v-tooltip top v-if="item.csv.trim() !== ''">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-on="on"
                  @click="DownloadCsv(item.societa + '/' + item.csv)"
                  v-blur
                  class="text-xs-center"
                >
                  mdi-cloud-download
                </v-icon>
              </template>
              <span>Download csv</span>
            </v-tooltip>
          </template>
          <!-- <template slot="items" slot-scope="props">
            <th class="text-xs-left">
              <v-checkbox
                :input-value="props.all"
                :indeterminate="props.indeterminate"
                primary
                hide-details
                @click.stop="toggleAll"
              ></v-checkbox>
            </th>
          </template> -->
          <!-- <template v-slot:items="props">
            <tr>
              <td
                :active="props.selected"
                @click="props.selected = !props.selected"
              >
                <v-checkbox
                  :input-value="props.selected"
                  primary
                  hide-details
                  v-if="props.item.csv.trim() !== ''"
                ></v-checkbox>
              </td>
              <td class="text-xs-left">{{ props.item.numero }}</td>
              <td class="text-xs-left">{{ props.item.data }}</td>
              <td class="text-xs-left">{{ props.item.imponibile }}</td>
              <td class="text-xs-left">{{ props.item.iva }}</td>
              <td class="text-xs-left">{{ props.item.totale }}</td>
              <td>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      v-if="props.item.pdf.trim() !== ''"
                      class="text-xs-center"
                      @click="
                        DownloadPdf(props.item.societa + '/' + props.item.pdf)
                      "
                      >mdi-cloud-download</v-icon
                    >
                  </template>
                  <span>Download pdf</span>
                </v-tooltip>
              </td>
              <td>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      v-if="props.item.csv.trim() !== ''"
                      class="text-xs-center"
                      @click="
                        DownloadCsv(props.item.societa + '/' + props.item.csv)
                      "
                      >mdi-cloud-download</v-icon
                    >
                  </template>
                  <span>Download csv</span>
                </v-tooltip>
              </td>
            </tr>
          </template> -->
          <!-- <template slot="pageText" slot-scope="{ pageStart, pageStop }"
            >{{ $t("key621") }}&nbsp;{{ pageStart }}&nbsp;{{
              $t("key622")
            }}&nbsp;{{ pageStop }}</template
          > -->
        </v-data-table>
      </v-form>
    </v-card>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    documentDialog: false,
    serviceError: false,
    noSelection: false,
    selezionati: [],
    selected: [],
    singleSelect: false,
    overlay: false,
    modal: false,
    data_partenza: "",
    minPartenza: "",
    maxPartenza: "",
    data_finale: "",
    minFinale: "",
    maxFinale: "",
    modal2: false,
    fatture: [],
    dati_fatture: [],
    tableTranslated: [],
    alertDate: false,
  }),
  created() {
    if (this.$i18n.locale == "it") {
      this.tableTranslated = [
        {
          text: "Numero documento",
          align: "left",
          value: "numero",
          sortable: false,
        },
        {
          text: "Data documento",
          align: "left",
          value: "data",
          sortable: false,
        },
        {
          text: "Imponibile",
          align: "left",
          value: "imponibile",
          sortable: false,
        },
        { text: "Iva", align: "left", value: "iva", sortable: false },
        { text: "Totale", align: "left", value: "totale", sortable: false },
        { text: "Pdf", align: "left", value: "pdf", sortable: false },
        { text: "Csv", align: "left", value: "csv", sortable: false },
      ];
    } else {
      this.tableTranslated = [
        {
          text: "Document number",
          align: "left",
          value: "numero",
          sortable: false,
        },
        {
          text: "Document date",
          align: "left",
          value: "data",
          sortable: false,
        },
        {
          text: "Taxable",
          align: "left",
          value: "imponibile",
          sortable: false,
        },
        { text: "Vat", align: "left", value: "iva", sortable: false },
        { text: "Total", align: "left", value: "totale", sortable: false },
        { text: "Pdf", align: "left", value: "pdf", sortable: false },
        { text: "Csv", align: "left", value: "csv", sortable: false },
      ];
    }
    this.$store.dispatch("getCodiciValidi", "ritiri");
    //faccio partire il timeout per il scollegamento automatico
    this.$store.dispatch("ImpostaScollegamento");
    //inizializzo le date per la selezione delle date sul filtro di ricerca
    this.data_partenza = this.addDays(new Date(), -180)
      .toISOString()
      .substr(0, 10);
    this.data_finale = this.addDays(new Date(), 0).toISOString().substr(0, 10);
    this.minPartenza = this.addDays(new Date(), -365)
      .toISOString()
      .substr(0, 10);
    this.maxPartenza = this.addDays(new Date(), 0).toISOString().substr(0, 10);
    this.minFinale = this.addDays(new Date(), -365).toISOString().substr(0, 10);
    this.maxFinale = this.addDays(new Date(), 0).toISOString().substr(0, 10);
    this.Cerca();
  },
  methods: {
    DownloadManuale() {
      var link =
        "http://clienti.arcospedizioni.it/download/manuale_fatture.pdf";
      window.open(link);
    },
    Selezionato(val, el) {
      if (!val) this.selected.push(el);
    },
    addDays(date, days) {
      date.setDate(date.getDate() + days);
      return date;
    },
    checkFinale() {
      this.$refs.dialog.save(this.data_partenza);
      this.minFinale = this.data_partenza;
      this.maxFinale = this.addDays(new Date(), 0).toISOString().substr(0, 10);
    },
    CambiaCodiceSelezionato() {
      this.overlay = true;
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/Login/GeneraNuovoToken/" +
            this.$store.state.MasterSelezionato
        )
        .then((res) => {
          this.$store.state.token = res.data;
          this.axios.defaults.headers.common["Authorization"] = res.data;
          this.RicreaPagina();
          //this.overlay = false;
        })
        .catch((err) => {
          this.$router.push({ path: `/errore` });
        });
    },
    RicreaPagina() {
      //reinizializzo tutti i dati
      this.$store.dispatch("ImpostaScollegamento");
      //inizializzo le date per la selezione delle date sul filtro di ricerca
      this.data_partenza = this.addDays(new Date(), -180)
        .toISOString()
        .substr(0, 10);
      this.data_finale = this.addDays(new Date(), 0)
        .toISOString()
        .substr(0, 10);
      this.minPartenza = this.addDays(new Date(), -365)
        .toISOString()
        .substr(0, 10);
      this.maxPartenza = this.addDays(new Date(), 0)
        .toISOString()
        .substr(0, 10);
      this.minFinale = this.addDays(new Date(), -365)
        .toISOString()
        .substr(0, 10);
      this.maxFinale = this.addDays(new Date(), 0).toISOString().substr(0, 10);
      this.fatture = [];
      this.dati_fatture = [];
      this.selected = [];
      this.Cerca();
    },
    Cerca() {
      this.overlay = true;
      if (
        this.data_partenza === null ||
        this.data_partenza === undefined ||
        this.data_partenza === ""
      ) {
        this.data_partenza = "1900-01-01";
      }
      if (
        this.data_finale === null ||
        this.data_finale === undefined ||
        this.data_finale === ""
      ) {
        this.data_finale = "1900-01-01";
      }
      var filtro = {
        dataPartenza: this.data_partenza,
        dataFinale: this.data_finale,
        ddtPartenza: null,
        ddtFinale: null,
        statoSpedizione: null,
      };
      this.fatture = [];
      this.dati_fatture = [];
      this.axios
        .post(this.$store.state.servicePath + "/api/Ftweb01t/Cerca", filtro)
        .then((res) => {
          res.data.forEach((element) => {
            var el = {
              selected: false,
              numero: element.numDoc,
              tipo: element.tipDoc,
              dataOrdinamento: element.dtFatt,
              data:
                element.dtFatt.toString().substr(6, 2) +
                "-" +
                element.dtFatt.toString().substr(4, 2) +
                "-" +
                element.dtFatt.toString().substr(0, 4),
              imponibile: element.impNetto.toLocaleString("it-IT") + " €",
              iva: element.totIva.toLocaleString("it-IT") + " €",
              totale:
                (
                  Number(element.impNetto) + Number(element.totIva)
                ).toLocaleString("it-IT") + " €",
              pdf: element.link2pdf,
              csv: element.link2csv,
              societa: element.societa,
            };
            this.fatture.push(el);
            element["totale"] = (
              Number(element.impNetto) + Number(element.totIva)
            ).toFixed(2);
            this.dati_fatture.push(element);
          });
          this.dati_fatture.sort((a, b) =>
            a.dtFatt > b.dtFatt ? 1 : b.dtFatt > a.dtFatt ? -1 : 0
          );
          this.overlay = false;
        })
        .catch((err) => {
          this.overlay = false;
          this.serviceError = true;
        });
    },
    FormattaNumero(numero) {
      numero = numero.toFixed(2).toString().replaceAll(".", ",");
      var numericParts = numero.split(",");
      if (numericParts.length > 1) {
        decimale = numero.split(",")[1];
      } else {
        decimale = "";
      }
      var numerica = numero.split(",")[0];
      var temp = numerica;
      var dots = 0;
      while (numerica / 1000 >= 1) {
        dots += 1;
        numerica = numerica / 1000;
      }
    },
    EstraiCsv() {
      var dati = [];
      var intestazione = [
        "SOCIETA'",
        "ANNO",
        "NUMERO DOCUMENTO",
        "DATA FATTURA",
        "CODICE CLIENTE",
        "ID FATTURA",
        "RAGIONE SOCIALE",
        "INDIRIZZO",
        "LOCALITA'",
        "PARTITA IVA",
        "IMPONIBILE",
        "IVA",
        "TOTALE FATTURA",
      ];

      dati.push(intestazione);
      this.dati_fatture.forEach((e) => {
        var riga = [];
        riga.push(e.societa.toString().trim());
        riga.push(e.anno.toString().trim());
        riga.push(e.numDoc.toString().trim());
        riga.push(e.dtFatt.toString().trim());
        riga.push(e.cliente.toString().trim());
        riga.push(e.idFatt.toString().trim());
        riga.push(e.ragSoc.toString().trim());
        riga.push(e.indFatt.toString().trim());
        riga.push(e.locFatt.toString().trim());
        riga.push(e.pivaFis.toString().trim());
        riga.push(e.impNetto.toString().trim());
        riga.push(e.totIva.toString().trim());
        riga.push(e.totale).toString().trim();
        dati.push(riga);
      });
      var universalBOM = "\uFEFF";
      let csvContent =
        "data:text/csv;charset=utf-8," +
        encodeURIComponent(dati.map((e) => e.join(";")).join("\n"));
      var encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", csvContent);
      link.setAttribute("download", "export.csv");
      link.click();
    },
    DownloadPdf(path_) {
      this.axios
        .post(
          this.$store.state.servicePath + "/api/Ftweb01t/DownloadAllegati",
          { path: path_.trim() }
        )
        .then((res) => {
          if (
            res.data.src === null ||
            res.data.src === undefined ||
            res.data.src === ""
          ) {
            this.documentDialog = true;
          } else {
            var pdf = res.data.src;
            var decodedString = atob(pdf);
            var byteCharacters = decodedString;
            var byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            var blob = new Blob([byteArray], {
              type: "application/pdf",
            });
            const fileObjectURL = URL.createObjectURL(blob);
            const blobUrl = URL.createObjectURL(blob);
            window.open(fileObjectURL);
          }
        })
        .catch(() => {
          this.serviceError = true;
        });
    },
    DownloadCsv(path_) {
      this.axios
        .post(
          this.$store.state.servicePath + "/api/Ftweb01t/DownloadAllegati",
          { path: path_.trim() }
        )
        .then((res) => {
          if (
            res.data.src === null ||
            res.data.src === undefined ||
            res.data.src === ""
          ) {
            return;
          } else {
            var csv = res.data.src;
            var decodedString = atob(csv);
            var byteCharacters = decodedString;
            var byteNumbers = new Array(byteCharacters.length);
            var universalBOM = "\uFEFF";
            var encodedUri = encodeURI(decodedString);
            let csvContent =
              "data:text/csv;charset=utf-8," +
              encodeURIComponent(decodedString);
            const link = document.createElement("a");
            link.setAttribute("href", csvContent);
            link.setAttribute("download", "export.csv");
            link.click();
          }
        })
        .catch(() => {
          this.serviceError = true;
        });
    },
    SelezionaTutto(items) {
      this.selezionati = [];
      items.forEach((el) => {
        this.selezionati.push(el);
      });
    },
    EstraiCsvMassivi() {
      var selectedFiltered = this.selected.filter((el) => el.csv.trim() != "");
      var csvList = [];
      var vecchio = 0;
      var nuovo = 0;
      var tipologia = 0;
      selectedFiltered.forEach((el) => {
        if (el.csv.includes("V1X")) vecchio += 1;
        else nuovo += 1;
        if (nuovo != 0 && vecchio != 0) {
          this.alertDate = true;
          return;
        }
        if (nuovo > 0) tipologia = 1;
        else if (vecchio > 0) tipologia = 0;
        csvList.push({
          path: el.societa + "/" + el.csv.trim(),
          fattura: el.numero,
        });
      });
      if (csvList.length != 0) {
        this.axios
          .post(
            this.$store.state.servicePath +
              "/api/Ftweb01t/DownloadAllegatiMultipli/" +
              tipologia,
            csvList
          )
          .then((response) => {
            var byteCharacters = response.data;
            var byteNumbers = new Array(byteCharacters.length);
            var universalBOM = "\uFEFF";
            var encodedUri = encodeURI(response.data);
            let csvContent =
              "data:text/csv;charset=utf-8," +
              encodeURIComponent(response.data);
            const link = document.createElement("a");
            link.setAttribute("href", csvContent);
            link.setAttribute("download", "export.csv");
            link.click();
            this.selected = [];
          })
          .catch(() => {
            this.serviceError = true;
          });
      } else {
        this.noSelection = true;
      }
    },
  },
  watch: {
    "$i18n.locale": function (val) {
      if (val == "it") {
        this.tableTranslated = [
          { text: "Numero documento", align: "left", value: "numero" },
          { text: "Data documento", align: "left", value: "data" },
          { text: "Imponibile", align: "left", value: "imponibile" },
          { text: "Iva", align: "left", value: "iva" },
          { text: "Totale", align: "left", value: "totale" },
          { text: "Pdf", align: "left", value: "pdf" },
          { text: "Csv", align: "left", value: "csv" },
        ];
      } else {
        this.tableTranslated = [
          { text: "Document number", align: "left", value: "numero" },
          { text: "Document date", align: "left", value: "data" },
          { text: "Taxable", align: "left", value: "imponibile" },
          { text: "Vat", align: "left", value: "iva" },
          { text: "Total", align: "left", value: "totale" },
          { text: "Pdf", align: "left", value: "pdf" },
          { text: "Csv", align: "left", value: "csv" },
        ];
      }
    },
  },
};
</script>
