<template>
  <v-container>
    <v-card>
      <v-card-title class="justify-center"
        ><h1>DOWNLOAD DOCUMENTAZIONE</h1></v-card-title
      >
      <hr />
      <v-card-text>
        <v-row class="d-flex justify-space-around">
          <v-col cols="12" sm="12" md="5">
            <h3 style="text-align: center">PDF</h3>
          </v-col>
      
          <v-col cols="12" sm="12" md="5">
            <h3 style="text-align: center">CSV</h3>
          </v-col>
      
          <v-col cols="12" sm="12" md="5">
            <v-btn
              large
              style="box-shadow: none; color: white; margin-left: 0px"
              color="#A0A4A7"
              block
              @click="Redirect('_LOCDIS.PDF')"
              >PDF - LOCALIT&Agrave; DISAGIATE</v-btn
            >
          </v-col>
      
          <v-col cols="12" sm="12" md="5">
            <v-btn
              large
              style="box-shadow: none; color: white; margin-left: 0px"
              color="#A0A4A7"
              block
              @click="Redirect('FLDIS.CSV')"
              >CSV - LOCALIT&Agrave; DISAGIATE</v-btn
            >
          </v-col>
      
          <v-col cols="12" sm="12" md="5">
            <v-btn
              large
              style="box-shadow: none; color: white; margin-left: 0px"
              color="#A0A4A7"
              block
              @click="Redirect('_LOCIMP.PDF')"
              >PDF - LOCALIT&Agrave; IMPERVIE</v-btn
            >
          </v-col>
      
          <v-col cols="12" sm="12" md="5">
            <v-btn
              large
              style="box-shadow: none; color: white; margin-left: 0px"
              color="#A0A4A7"
              block
              @click="Redirect('FLIMP.CSV')"
              >CSV - LOCALIT&Agrave; IMPERVIE</v-btn
            >
          </v-col>
      
          <v-col cols="12" sm="12" md="5">
            <v-btn
              large
              style="box-shadow: none; color: white; margin-left: 0px"
              color="#A0A4A7"
              block
              @click="Redirect('_LOCBAL.PDF')"
              >PDF - LOCALIT&Agrave; BALNEARI</v-btn
            >
          </v-col>
      
          <v-col cols="12" sm="12" md="5">
            <v-btn
              large
              style="box-shadow: none; color: white; margin-left: 0px"
              color="#A0A4A7"
              block
              @click="Redirect('FLBAL.CSV')"
              >CSV - LOCALIT&Agrave; BALNEARI</v-btn
            >
          </v-col>
      
          <v-col cols="12" sm="12" md="5">
            <v-btn
              large
              style="box-shadow: none; color: white; margin-left: 0px"
              color="#A0A4A7"
              block
              @click="Redirect('_LOCDIFR.PDF')"
              >PDF - LOCALIT&Agrave; DISAGIATE FRANCIA</v-btn
            >
          </v-col>
      
          <v-col cols="12" sm="12" md="5">
            <v-btn
              large
              style="box-shadow: none; color: white; margin-left: 0px"
              color="#A0A4A7"
              block
              @click="Redirect('FLDISFR.CSV')"
              >CSV - LOCALIT&Agrave; DISAGIATE FRANCIA</v-btn
            >
          </v-col>
      
          <v-col cols="12" sm="12" md="5">
            <v-btn
              large
              style="box-shadow: none; color: white; margin-left: 0px"
              color="#A0A4A7"
              block
              @click="Redirect('_LOCDIES.PDF')"
              >PDF - LOCALIT&Agrave; DISAGIATE SPAGNA</v-btn
            >
          </v-col>
      
          <v-col cols="12" sm="12" md="5">
            <v-btn
              large
              style="box-shadow: none; color: white; margin-left: 0px"
              color="#A0A4A7"
              block
              @click="Redirect('FLDISES.CSV')"
              >CSV - LOCALIT&Agrave; DISAGIATE SPAGNA</v-btn
            >
          </v-col>
      
          <v-col cols="12" sm="12" md="5">
            <v-btn
              large
              style="box-shadow: none; color: white; margin-left: 0px"
              color="#A0A4A7"
              block
              @click="Redirect('_LOCDICH.PDF')"
              >PDF - LOCALIT&Agrave; DISAGIATE SVIZZERA</v-btn
            >
          </v-col>
      
          <v-col cols="12" sm="12" md="5">
            <v-btn
              large
              style="box-shadow: none; color: white; margin-left: 0px"
              color="#A0A4A7"
              block
              @click="Redirect('FLDISCH.CSV')"
              >CSV - LOCALIT&Agrave; DISAGIATE SVIZZERA</v-btn
            >
          </v-col>
    
          <v-col cols="12" sm="12" md="5">
            <v-btn
              large
              style="box-shadow: none; color: white; margin-left: 0px"
              color="#A0A4A7"
              block
              @click="Redirect('_LOCFCS.PDF')"
              >PDF - CAP FACCHINAGGIO AUTOMATICO</v-btn
            >
          </v-col>
    
          <v-col cols="12" sm="12" md="5">
            <v-btn
              large
              style="box-shadow: none; color: white; margin-left: 0px"
              color="#A0A4A7"
              block
              @click="Redirect('FLFCS.CSV')"
              >CSV - CAP FACCHINAGGIO AUTOMATICO</v-btn
            >
          </v-col>
    
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      message: "",
    };
  },
  created() {
    this.$store.dispatch("ImpostaScollegamento");
  },
  mounted() {
    this.CheckJWT();
  },
  methods: {
    CheckJWT() {
      this.axios
        .post(this.$store.state.servicePath + "/api/Login/checkJwt", {
          route: this.$route.path,
        })
        .then((res) => {
          if (res.data.status == "ko") {
            this.$router.push({ path: `/errore` });
          }
        })
        .catch((err) => {
          this.$router.push({ path: `/errore` });
        });
    },
    Redirect(redirect) {
      var link = "https://clienti.arcospedizioni.it/download/" + redirect;
      window.open(link);
    },
  },
};
</script>