<template>
  <v-container grid-list-md text-xs-center>
    <v-layout justify-center>
      <v-dialog v-model="dialogFattu" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">{{ $t("key434") }}</v-card-title>
          <v-card-text>{{ dialogText }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" dark text @click="dialogFattu = false"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="dialogErrore" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">{{ $t("key566") }}</v-card-title>
          <v-card-text>{{ errorText }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" depressed @click="dialogErrore = false"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <!-- POPUP AVVISI -->
    <v-dialog
      v-model="dialogAvvisi"
      max-width="900px"
      persistent
      id="avvisi_scroll"
    >
      <v-card>
        <v-card-text class="pa-0 ma-0">
          <MostraAvviso :avviso="dialogAvviso" />
          <v-checkbox
            :label="$t('key750')"
            v-model="dialogAvvisiLetto"
            class="ml-4"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            depressed
            @click="prossimoAvviso"
            :loading="dialogAvvisiLoading"
            :disabled="dialogAvvisiLoading || !dialogAvvisiLetto"
          >
            {{ $t("key118") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-layout>
      <v-flex xs12 sm12>
        <v-card
          class="d-flex"
          style="flex-direction: column; align-items: center"
        >
          <v-card-title
            style="font-size: 2em; font-family: 'hk-grotesk'"
            class="mb-8"
            >{{ $t("key329") }}
            {{ this.$store.state.userdata.displayname }}</v-card-title
          >
          <!-- <v-form v-model="valid">
            <v-card-text>
              <v-flex sm12>
                <p>{{ $t("key333") }}.</p>
              </v-flex>
            </v-card-text>
          </v-form> -->
          <!-- modifica per cruscotto clienti -->

          <v-layout row wrap class="pa-2 justify-center" style="width: 100%">
            <v-flex
              v-for="(menu, i) in trasporti"
              :key="`${menu}${i}`"
              xs3
              sm3
              md3
            >
              <v-card
                :hover="true"
                dark
                color="primary"
                @click="Redirect(menu.route != '' ? menu.route : menu.href)"
                :disabled="menu.disabled"
                style="height: 100%"
              >
                <v-card-text
                  style="font-size: 18px; font-weight: bold"
                  class="d-flex flex-column justify-center align-center"
                >
                  <v-icon class="mb-1">{{ menu.icon }}</v-icon>
                  <span style="color: white; text-align: center">{{
                    menu.title
                  }}</span>
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex xs3 sm3 md3 v-if="logistica.length == 0">
              <v-card
                :hover="true"
                dark
                color="primary"
                @click="Redirect('/')"
                style="height: 100%"
              >
                <v-card-text
                  style="font-size: 18px; font-weight: bold"
                  class="d-flex flex-column justify-center align-center"
                >
                  <v-icon class="mb-1">mdi-logout</v-icon>
                  <span style="color: white; text-align: center">Logout</span>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
          <h2 v-if="logistica.length != 0">{{ $t("key668") }}</h2>
          <v-layout row wrap class="pa-2 justify-center" style="width: 100%">
            <v-flex
              v-for="(menu, i) in logistica"
              :key="`${menu}${i}`"
              xs3
              sm3
              md3
            >
              <v-card
                :hover="true"
                dark
                color="primary"
                @click="Redirect(menu.route != '' ? menu.route : menu.href)"
                :disabled="menu.disabled"
                style="height: 100%"
              >
                <v-card-text
                  style="font-size: 18px; font-weight: bold"
                  class="d-flex flex-column justify-center align-center"
                >
                  <v-icon class="mb-1">{{ menu.icon }}</v-icon>
                  <span style="color: white; text-align: center">{{
                    menu.title
                  }}</span>
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex xs3 sm3 md3 v-if="logistica.length != 0">
              <v-card
                :hover="true"
                dark
                color="primary"
                @click="Redirect('/')"
                style="height: 100%"
              >
                <v-card-text
                  style="font-size: 18px; font-weight: bold"
                  class="d-flex flex-column justify-center align-center"
                >
                  <v-icon class="mb-1">mdi-logout</v-icon>
                  <span style="color: white; text-align: center">Logout</span>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card>

        <!-- <h2>Gentile Cliente,  a seguito di alcune attività di aggiornamento della nostra Area Clienti, finalizzate a rendere sempre più efficiente lo strumento che mettiamo a disposizione dei nostri Clienti, siamo ad informala che Giovedì 27 Ottobre dalle ore 19.00 non sarà possibile accedere al nostro sito WEB “clienti.arcospedizioni.it”. Il sito tornerà attivo la mattina seguente.
          Scusandoci per il disagio la ringraziamo della collaborazione
          </h2> -->
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import MostraAvviso from "../components/MostraAvviso.vue";

export default {
  components: {
    MostraAvviso,
  },
  data() {
    return {
      valid: false,
      menuList: [],
      dialogFattu: false,
      dialogText: "",
      dialogErrore: false,
      dialogAvvisi: false,
      dialogAvviso: {},
      dialogAvvisiLoading: false,
      dialogAvvisiLetto: false,
      errorText: "",
      trasporti: [],
      logistica: [],
    };
  },
  created() {
    this.$store.dispatch("ImpostaScollegamento");
  },
  mounted() {
    this.axios
      .get(
        this.$store.state.servicePath +
          "/api/Login/GeneraNuovoToken/" +
          this.$store.state.CodiceOriginale
      )
      .then((res) => {
        this.$store.state.token = res.data;
        this.axios.defaults.headers.common["Authorization"] = res.data;
        // localStorage.setItem("jwt", res.data);
        this.$store.dispatch("ImpostaScollegamento");
        this.$store.dispatch("getCodiciOperativi");
        if (!this.$store.state.cappario_ricevuto) {
          this.$store.dispatch("getCappario");
        }
        this.getMenus();
        this.getAvvisi();
      })
      .catch(() => {
        this.$router.push({ path: `/errore` });
      });
    // console.log(this.$store.state.list_cappario.length)
    // if(this.$store.state.list_cappario.length == 0)
    //   this.$store.dispatch("getCappario");
    //var test = localStorage.getItem('cappario');
  },
  methods: {
    getMenus() {
      this.menuList = [];
      if (this.$root.$i18n.locale == "it") {
        this.$store.state.userdata.menus.forEach((element) => {
          var el = {
            icon: element.icona,
            title: element.descrizione,
            route: element.route,
            href: element.href,
            disabled: false,
            id: element.id,
            ordine: element.ordine,
          };
          if (el.title != "Home") {
            this.menuList.push(el);
          }
        });
      } else {
        this.$store.state.userdata.menus.forEach((element) => {
          var eldes = element.descrizione;
          if (this.axios.defaults.headers.common["Language"] != "it-IT") {
            if (element.descrizione == "Le tue fatture") {
              eldes = "Invoices";
            }
            if (element.descrizione == "Rubrica") {
              eldes = "Database";
            }
            if (element.descrizione == "Inserimento ritiri") {
              eldes = "Enter pick up order";
            }
            if (element.descrizione == "Località impervie") {
              eldes = "Remote localities";
            }
            if (element.descrizione == "Località disagiate") {
              eldes = "Remote localities";
            }
            if (element.descrizione == "Bollettazione") {
              eldes = "Enter shipment";
            }
            if (element.descrizione == "Nuovi Ritiri") {
              eldes = "Enter a pick up order";
            }
            if (element.descrizione == "Tracking spedizioni") {
              eldes = "Shipment tracking";
            }
            if (element.descrizione == "Tracking ritiri") {
              eldes = "Pick up tracking";
            }
            if (element.descrizione == "Download POD massivo") {
              eldes = "Mass. POD download";
            }
            if (element.descrizione == "Download rimessi massivo") {
              eldes = "Mass. DDT download";
            }
            if (element.descrizione == "Le tue spedizioni - Grafici") {
              eldes = "Shipments - Graphs";
            }
            if (element.descrizione == "Kpi-Rese") {
              eldes = "KPI";
            }
            if (element.descrizione == "Gestione utenza") {
              eldes = "User profile admin";
            }
            if (element.descrizione == "Gestione Utenti") {
              eldes = "Users admin";
            }
            if (element.descrizione == "Stati corrispondenti") {
              eldes = "Foreign status admin";
            }
            if (element.descrizione == "Sedi") {
              eldes = "Branches";
            }
            if (element.descrizione == "Verifica E-Mail utenti") {
              eldes = "Email check";
            }
            if (element.descrizione == "Gestione Codici Master") {
              eldes = "Master codes admin";
            }
            if (element.descrizione == "Movimenti Di Magazzino") {
              eldes = "Warehouse Movements";
            }
            if (element.descrizione == "Esistenze Di Magazzino") {
              eldes = "Warehouse Summary Report";
            }
            if (element.descrizione == "Documenti Ingresso") {
              eldes = "Warehouse Receiving Order";
            }
            if (element.descrizione == "Documenti Uscita") {
              eldes = "Warehouse Shipping Order";
            }
            if (element.descrizione == "Avvisi e Informative") {
              eldes = "Notices and Information";
            }
          }
          var el = {
            icon: element.icona,
            title: eldes,
            route: element.route,
            href: element.href,
            disabled: false,
            id: element.id,
            ordine: element.ordine,
          };
          if (el.title != "Home") {
            this.menuList.push(el);
          }
        });
      }
      this.trasporti = [];
      this.logistica = [];

      this.menuList.forEach((el) => {
        if (
          (el.title == "Le tue fatture" || el.title == "Invoices") &&
          !el.icon.includes("mdi-")
        ) {
          el.icon = "mdi-account-multiple";
        }
        if (
          (el.title == "Rubrica" || el.title == "Database") &&
          !el.icon.includes("mdi-")
        ) {
          el.icon = "mdi-contacts";
        }
        if (
          (el.title == "Inserimento ritiri" ||
            el.title == "Enter pick up order") &&
          !el.icon.includes("mdi-")
        ) {
          el.icon = "mdi-bus-clock";
        }
        if (
          (el.title == "Località impervie" ||
            el.title == "Remote localities") &&
          !el.icon.includes("mdi-")
        ) {
          el.icon = "mdi-clipboard-arrow-down";
        }
        if (
          (el.title == "Località disagiate" ||
            el.title == "Remote localities") &&
          !el.icon.includes("mdi-")
        ) {
          el.icon = "mdi-clipboard-arrow-down";
        }
        if (
          (el.title == "Bollettazione" || el.title == "Enter shipment") &&
          !el.icon.includes("mdi-")
        ) {
          el.icon = "mdi-layers";
        }
        if (
          (el.title == "Nuovi Ritiri" || el.title == "Enter pick up order") &&
          !el.icon.includes("mdi-")
        ) {
          el.icon = "mdi-bus-clock";
        }
        if (
          (el.title == "Tracking spedizioni" ||
            el.title == "Shipment tracking") &&
          !el.icon.includes("mdi-")
        ) {
          el.icon = "mdi-crosshairs-gps";
        }
        if (
          (el.title == "Tracking ritiri" || el.title == "Pick up tracking") &&
          !el.icon.includes("mdi-")
        ) {
          el.icon = "mdi-crosshairs-gps";
        }
        if (
          (el.title == "Download POD massivo" ||
            el.title == "Mass. POD download") &&
          !el.icon.includes("mdi-")
        ) {
          el.icon = "mdi-cloud-download";
        }
        if (
          (el.title == "Download rimessi massivo" ||
            el.title == "Mass. DDT download") &&
          !el.icon.includes("mdi-")
        ) {
          el.icon = "mdi-cloud-download";
        }
        if (
          (el.title == "Le tue spedizioni - Grafici" ||
            el.title == "Shipments - Graphs") &&
          !el.icon.includes("mdi-")
        ) {
          el.icon = "mdi-poll";
        }
        if (
          (el.title == "Kpi-Rese" || el.title == "KPI") &&
          !el.icon.includes("mdi-")
        ) {
          el.icon = "mdi-account-multiple";
        }
        if (
          (el.title == "Gestione utenza" || el.title == "User profile admin") &&
          !el.icon.includes("mdi-")
        ) {
          el.icon = "mdi-domain";
        }
        if (
          (el.title == "Gestione Utenti" || el.title == "Users admin") &&
          !el.icon.includes("mdi-")
        ) {
          el.icon = "mdi-account-circle";
        }
        if (
          (el.title == "Stati corrispondenti" ||
            el.title == "Foreign status admin") &&
          !el.icon.includes("mdi-")
        ) {
          el.icon = "mdi-alarm-snooze";
        }
        if (
          (el.title == "Sedi" || el.title == "Branches") &&
          !el.icon.includes("mdi-")
        ) {
          el.icon = "mdi-home";
        }
        if (
          (el.title == "Verifica E-Mail utenti" || el.title == "Email check") &&
          !el.icon.includes("mdi-")
        ) {
          el.icon = "mdi-cloud-download";
        }
        if (
          (el.title == "Gestione Codici Master" ||
            el.title == "Master codes admin") &&
          !el.icon.includes("mdi-")
        ) {
          el.icon = "mdi-account-multiple";
        }
        if (
          (el.title == "Movimenti Di Magazzino" ||
            el.title == "Warehouse Movements") &&
          !el.icon.includes("mdi-")
        ) {
          el.icon = "mdi-swap-horizontal-bold";
        }
        if (
          (el.title == "Esistenze Di Magazzino" ||
            el.title == "Warehouse Summary Report") &&
          !el.icon.includes("mdi-")
        ) {
          el.icon = "mdi-warehouse ";
        }
        if (
          (el.title == "Documenti Ingresso" ||
            el.title == "Warehouse Receiving Order") &&
          !el.icon.includes("mdi-")
        ) {
          el.icon = "mdi-file-upload";
        }
        if (
          (el.title == "Documenti Uscita" ||
            el.title == "Warehouse Shipping Order") &&
          !el.icon.includes("mdi-")
        ) {
          el.icon = "mdi-file-download";
        }
        if (
          (el.title == "Avvisi e Informative" ||
            el.title == "Notices and Information") &&
          !el.icon.includes("mdi-")
        ) {
          el.icon = "mdi-bell";
        }

        if (el.ordine <= 100) {
          this.trasporti.push(el);
        } else {
          this.logistica.push(el);
        }
      });
    },
    Redirect(link) {
      this.dialogFattu = false;
      if (
        link.includes("NuoviRitiri") &&
        this.$store.state.selectCodici.length == 0
      ) {
        this.axios
          .get(
            this.$store.state.servicePath +
              "/api/Ttrcd10f/Fattu/" +
              this.$store.state.CodiceOriginale
          )
          .then((res) => {
            if (res.data.trim() == "FATTU") {
              if (this.$root.$i18n.locale == "it") {
                this.dialogText = `Non è possibile inserire un RITIRO con questa mail in quanto collegata al codice ${this.$store.state.CodiceOriginale} che risulta di Fatturazione e non Operativo.`;
              } else {
                this.dialogText = `It is not possible to enter a PICK UP ORDER with this email as it is linked to the code ${this.$store.state.CodiceOriginale} which is Billing and not Operational.`;
              }
              this.dialogFattu = true;
            } else {
              if (link.includes("http")) {
                window.open(link);
              } else {
                if (!link.includes("Tracking")) {
                  if (link == "/") {
                    this.$store.dispatch("logout").then(() => {
                      this.$router.push("/");
                    });
                  } else {
                    this.axios
                      .get(
                        this.$store.state.servicePath +
                          "/api/Login/GeneraNuovoToken/" +
                          this.$store.state.CodiceOriginale
                      )
                      .then((res) => {
                        this.$store.state.token = res.data;
                        this.axios.defaults.headers.common["Authorization"] =
                          res.data;
                        this.$router.push({ path: link });
                      })
                      .catch(() => {
                        this.$router.push({ path: `/errore` });
                      });
                  }
                } else {
                  this.$router.push({ path: link });
                }
              }
            }
          })
          .catch(() => {
            this.errorText = "";
            this.errorText = this.$t("key631");
            this.dialogErrore = true;
          });
      } else {
        if (link.includes("http")) {
          window.open(link);
        } else {
          if (!link.includes("Tracking")) {
            if (link == "/") {
              this.$store.dispatch("logout").then(() => {
                this.$router.push("/");
              });
            } else {
              this.axios
                .get(
                  this.$store.state.servicePath +
                    "/api/Login/GeneraNuovoToken/" +
                    this.$store.state.CodiceOriginale
                )
                .then((res) => {
                  this.$store.state.token = res.data;
                  this.axios.defaults.headers.common["Authorization"] =
                    res.data;
                  this.$router.push({ path: link });
                })
                .catch(() => {
                  this.$router.push({ path: `/errore` });
                });
            }
          } else {
            this.$router.push({ path: link });
          }
        }
      }
    },
    getAvvisi() {
      //  Chiamata all'api per gli avvisi non letti
      this.axios
        .get(this.$store.state.servicePath + "/api/ArAvvisis/listaAvvisi/1")
        .then((res) => {
          this.$store.state.avvisiNonLetti = res.data.avvisiNonLetti ? res.data.avvisiNonLetti : []

          if (this.$store.state.avvisiNonLetti.filter(el => el.accettazioneObbligatoria && (!el.dataFine || new Date() <= new Date(el.dataFine))).length > 0) {
            this.prossimoAvviso()
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async prossimoAvviso() {
      //  Controllo se l'evento è stato scaturito dal tasto del popup (se c'è un dialog aperto) di conseguenza segnalo l'avviso come letto
      if (this.dialogAvviso.id && this.dialogAvvisi) {
        this.dialogAvvisiLoading = true;

        try {
          await this.leggiAvviso(this.dialogAvviso.id);

          this.dialogAvvisi = false;
          this.dialogAvvisiLoading = false;
          this.dialogAvvisiLetto = false;
          //  In caso di avvisi con overflow resetto anche lo scroll
          document
            .getElementsByClassName(
              "v-dialog v-dialog--active v-dialog--persistent"
            )[0]
            .scrollTo(0, 0);
        } catch (e) {
          this.dialogErrore = true;
          this.errorText = this.$t("key618");
          return;
        }
      }

      this.$nextTick(() => {
        var avvisiObbligatori = this.$store.state.avvisiNonLetti.filter(
          el => el.accettazioneObbligatoria && (!el.dataFine || new Date() <= new Date(el.dataFine))
        );

        if (avvisiObbligatori.length > 0) {
          this.dialogAvviso = avvisiObbligatori[0];
          this.dialogAvvisi = true;
        }
      });
    },
    leggiAvviso(id) {
      return new Promise((resolve, reject) => {
        this.axios
          .post(`${this.$store.state.servicePath}/api/ArAvvisis/letto/${id}`)
          .then((res) => {
            this.$store.state.avvisiNonLetti =
              this.$store.state.avvisiNonLetti.filter((el) => el.id != id);
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      });
    },
  },
  watch: {
    "$i18n.locale": function () {
      this.getMenus();
    },
  },
};
</script>