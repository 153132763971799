<template>
  <v-container fluid>
    <v-card>
      <v-container fluid>
        <v-form ref="formFiltro" v-model="validFiltro">
          <v-row class="d-flex justify-center pt-2">
            <v-col cols="12" sm="6" md="4" class="py-0">
              <v-menu
                v-model="menu_inizio"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="data_inizio_formatted"
                    :label="$t('key035')"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:append="menu_inizio = true"
                    :rules="[range6Mesi]"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filtro.data_inizio"
                  full-width
                  @input="menu_inizio = false"
                  color="primary"
                  locale="it-IT"
                  first-day-of-week="1"
                  :max="filtro.data_fine"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="py-0">
              <v-menu
                v-model="menu_fine"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="data_fine_formatted"
                    :label="$t('key036')"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:append="menu_fine = true"
                    :rules="[range6Mesi]"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filtro.data_fine"
                  @input="menu_fine = false"
                  color="primary"
                  locale="it-IT"
                  first-day-of-week="1"
                  :min="filtro.data_inizio"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col
              v-if="clienti_list.length > 0"
              cols="12"
              sm="12"
              md="4"
              class="py-0"
            >
              <v-autocomplete
                :label="$t('key633')"
                v-model="filtro.admin_cliente"
                :items="clienti_list"
                auto-select-first
                clearable
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center pb-4">
            <v-col cols="12" sm="6" md="4" class="py-0">
              <v-select
                :items="listaTipoMov"
                :label="$t('key637')"
                v-model="filtro.tipo_movimento"
                hide-details
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="py-0">
              <v-select
                :items="listaCausali"
                :label="$t('key638')"
                v-model="filtro.causale"
                hide-details
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="4" class="py-0">
              <v-text-field
                v-model="filtro.cd_articolo"
                :label="$t('key639')"
                hide-details
              />
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card>
    <v-card class="mt-3 py-0">
      <v-container fluid class="py-0">
        <v-data-table
          fixed-header
          height="calc(100dvh - 397px)"
          :headers="headers"
          :items="filteredItems"
          :loading="loading"
          :items-per-page="itemsPerPage"
          :page.sync="data_table_page"
          sort-by="mvdreg"
          :must-sort="true"
          :no-data-text="
            clienti_list.length > 0 && !filtro.admin_cliente
              ? $t('key640')
              : $vuetify.noDataText
          "
          :search="search"
          @current-items="getData"
        >
          <template v-slot:top>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model="search"
                label="Cerca"
                prepend-inner-icon="mdi-magnify"
                clearable
              ></v-text-field>
            </v-col>
          </template>
          <template v-slot:[`item.mvdtsc`]="{ item }">
            {{ formatAS400toData(item.mvdtsc) }}
          </template>
          <template v-slot:[`item.mvdreg`]="{ item }">
            {{ formatAS400toData(item.mvdreg) }}
          </template>
          <template v-slot:[`item.mvddoc`]="{ item }">
            {{ formatAS400toData(item.mvddoc) }}
          </template>
          <template v-slot:[`item.mvcasc`]="{ item }">
            {{
              axios.defaults.headers.common["Language"] == "it-IT"
                ? item.mvcasc
                : item.mvcasc == "C"
                ? "L"
                : "U"
            }}
          </template>
          <template v-slot:[`footer.prepend`]>
            <v-btn
              @click="downloadCSV()"
              text
              :fab="$vuetify.breakpoint.xs"
              :small="$vuetify.breakpoint.xs"
              color="grey darken-3"
            >
              <span v-if="!$vuetify.breakpoint.xs">
                {{ $t("key619") }}
                <v-icon right> mdi-download </v-icon>
              </span>
              <v-icon v-else> mdi-download </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-container>
    </v-card>
    <!-- EXPORT DIALOG -->
    <v-dialog v-model="dialog_CSV" max-width="500px">
      <v-card>
        <v-card-title class="headline pt-4">Download CSV</v-card-title>
        <v-card-text>
          <div class="mt-4" v-if="!dialog_CSV_loading">
            <p style="display: inline">{{ $t("key641") }}</p>
            <a
              :download="`Export_movimenti_${date_export}.csv`"
              :href="file_CSV"
              style="text-decoration: none; display: inline"
              class="ml-4"
            >
              <v-btn @click="dialog_CSV = false" color="primary">
                Download
                <v-icon right> mdi-download </v-icon>
              </v-btn>
            </a>
          </div>
          <div class="d-flex align-center flex-column mt-4" v-else>
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" depressed @click="dialog_CSV = false">{{
            $t("key118")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "Movimenti",
  data: () => ({
    //Form ricerca
    filtro: {
      data_inizio: "",
      data_fine: "",
      tipo_movimento: "",
      causale: -1,
      cd_articolo: "",
      admin_cliente: null, //200298 400257
    },
    listaCausali: ["TUTTE"],
    menu_inizio: false,
    menu_fine: false,
    search: "",
    clienti_list: [],
    validFiltro: true,
    //Tabella
    totalItems: 0,
    items: [],
    loading: false,
    options: {},
    data_table_page: 1,
    itemsPerPage: 10,
    currentItems: null,
    //Export
    dialog_CSV: false,
    dialog_CSV_loading: false,
    date_export: null,
    file_CSV: null,
  }),
  created() {
    //  Ripristino filtro della sessione
    var session_filtro = sessionStorage.getItem("filtroMov");
    var session_search = sessionStorage.getItem("searchMov");

    if (session_filtro) {
      this.filtro = JSON.parse(session_filtro);
    } else {
      var minDate = new Date(Date.now() - 15 * 24 * 60 * 60 * 1000); //15 giorni prima di oggi
      var today = new Date();
      this.filtro.data_inizio = `${minDate.getFullYear()}-${(
        minDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${minDate.getDate().toString().padStart(2, "0")}`;
      this.filtro.data_fine = `${today.getFullYear()}-${(today.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`;
    }

    if (session_search != "null") {
      this.search = session_search;
    }

    this.$nextTick(() => {
      this.getCausali();

      this.getClienti()
        .catch(() => {
          //  Non sono admin controllo se sono codice master
          if (this.$store.state.visualizzaSelectMaster) {
            this.clienti_list = this.$store.state.codiciDaVisualizzare;
          }
        })
        .finally(() => {
          this.loading = false;

          this.filtro.admin_cliente = this.$store.state.CodiceOriginale;
          this.CambiaCodiceSelezionato().then(() => {
            this.$refs.formFiltro.validate();

            this.$nextTick(() => {
              if (!this.validFiltro) {
                this.loading = false;
                return;
              }

              this.getDataFromApi();
            });
          });
        });
    });
  },
  computed: {
    data_inizio_formatted() {
      if (this.filtro.data_inizio) {
        return new Date(this.filtro.data_inizio).toLocaleDateString("it-IT");
      } else {
        return "";
      }
    },
    data_fine_formatted() {
      if (this.filtro.data_fine) {
        return new Date(this.filtro.data_fine).toLocaleDateString("it-IT");
      } else {
        return "";
      }
    },
    filteredItems() {
      return this.items.filter((el) => {
        if (
          this.filtro.tipo_movimento != "" &&
          el.mvcasc.toUpperCase() != this.filtro.tipo_movimento.toUpperCase()
        ) {
          return false;
        }

        if (
          this.filtro.causale != "TUTTE" &&
          el.tldcam.toUpperCase() != this.filtro.causale.toUpperCase()
        ) {
          return false;
        }

        if (
          this.filtro.cd_articolo &&
          !el.mvcart
            .toUpperCase()
            .includes(this.filtro.cd_articolo.toUpperCase())
        ) {
          return false;
        }

        return true;
      });
    },
    listaTipoMov() {
      return [
        { text: this.$t("key637"), value: "" },
        { text: this.$t("key642"), value: "C" },
        { text: this.$t("key643"), value: "S" },
      ];
    },
    headers() {
      return [
        { text: this.$t("key644"), value: "mvdreg" },
        { text: this.$t("key645"), value: "mvcasc" },
        { text: this.$t("key646"), value: "mvtdoc" },
        { text: this.$t("key647"), value: "mvndoc" },
        { text: this.$t("key648"), value: "mvddoc" },
        { text: this.$t("key649"), value: "mvdesc" },
        { text: this.$t("key650"), value: "tldcam" },
        { text: this.$t("key639"), value: "mvcart" },
        { text: this.$t("key651"), value: "ardesc" },
        { text: this.$t("key652"), value: "mvnlot" },
        { text: this.$t("key653"), value: "mvdtsc" },
        { text: "U.M.", value: "arumma" },
        { text: this.$t("key654"), value: "quantita" },
      ];
    },
  },
  methods: {
    formatAS400toData(as400_data) {
      as400_data = as400_data.toString();

      if (as400_data.length < 8) {
        return "";
      }

      return `${as400_data.substr(6, 8)}/${as400_data.substr(
        4,
        2
      )}/${as400_data.substr(0, 4)}`;
    },
    getCausali() {
      this.axios
        .get(`${this.$store.state.servicePath}/api/Tlcam10f`)
        .then((response) => {
          this.listaCausali.push(...response.data);
          if (this.filtro.causale == -1) {
            this.filtro.causale = "TUTTE";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDataFromApi() {
      return new Promise((resolve) => {
        this.loading = true;
        this.getMovimenti().then((data) => {
          this.items = data;
          this.loading = false;
          resolve();
        });
      });
    },
    getMovimenti() {
      var inizio = encodeURIComponent(
        this.filtro.data_inizio.replaceAll("-", "")
      );
      var fine = encodeURIComponent(this.filtro.data_fine.replaceAll("-", ""));

      return new Promise((resolve, reject) => {
        this.axios
          .get(
            `${this.$store.state.servicePath}/api/Movma10f/${inizio}/${fine}`
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    getClienti() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        this.axios
          .get(`${this.$store.state.servicePath}/api/Ttrcd10f/TraspLog/T`)
          .then((response) => {
            this.clienti_list = response.data.map((el) => {
              return {
                text: `${el["trccli"]} - ${el["trcrgs"].trim()}`,
                value: el["trccli"],
              };
            });
            resolve();
          })
          .catch((error) => {
            console.log(error);
            reject();
          });
      });
    },
    CambiaCodiceSelezionato() {
      this.loading = true;

      return new Promise((resolve, reject) => {
        this.axios
          .get(
            `${this.$store.state.servicePath}/api/Login/GeneraNuovoToken/${this.filtro.admin_cliente}`
          )
          .then((res) => {
            this.$store.state.token = res.data;
            this.axios.defaults.headers.common["Authorization"] = res.data;
            // localStorage.setItem("jwt", res.data);
            this.$store.dispatch("ImpostaScollegamento");
            // this.getMonths();

            resolve();
          })
          .catch((err) => {
            this.$router.push({ path: `/errore` });
          });
      });
    },
    getData(e) {
      this.currentItems = e;
    },
    downloadCSV() {
      this.dialog_CSV_loading = true;
      this.dialog_CSV = true;

      var itemsBuffer = this.itemsPerPage;
      this.itemsPerPage = -1;

      this.$nextTick(() => {
        var csv = "";
        csv += `"${this.$t("key644")}";"${this.$t("key645")}";"${this.$t(
          "key646"
        )}";"${this.$t("key647")}";"${this.$t("key648")}";"${this.$t(
          "key649"
        )}";"${this.$t("key650")}";"${this.$t("key639")}";"${this.$t(
          "key651"
        )}";"${this.$t("key652")}";"${this.$t("key653")}";"U.M.";"${this.$t(
          "key654"
        )}"\n`;
        // csv += `"DATA MOVIMENTO";"C/S";"DOC. TIPO";"DOC. NR.";"DOC. DATA";"DESCRIZIONE SUPPLEMENTARE";"CAUSALE";"CODICE ARTICOLO";"DESCRIZIONE";"LOTTO";"SCADENZA";"UM";"QTA"\n`;
        this.currentItems.forEach((row) => {
          csv += `"${
            row["mvdreg"] > 0 ? this.formatAS400toData(row["mvdreg"]) : ""
          }";`;
          csv += `"${row["mvcasc"]}";`;
          csv += `"${row["mvtdoc"]}";`;
          csv += `"${
            /^\d+$/.test(row["mvndoc"]) ? "'" + row["mvndoc"] : row["mvndoc"]
          }";`;
          csv += `"${
            row["mvddoc"] > 0 ? this.formatAS400toData(row["mvddoc"]) : ""
          }";`;
          csv += `"${row["mvdesc"]}";`;
          csv += `"${row["tldcam"]}";`;
          csv += `"${row["mvcart"]}";`;
          csv += `"${row["ardesc"]}";`;
          csv += `"${
            /^\d+$/.test(row["mvnlot"]) ? "'" + row["mvnlot"] : row["mvnlot"]
          }";`;
          csv += `"${
            row["mvdtsc"] > 0 ? this.formatAS400toData(row["mvdtsc"]) : ""
          }";`;
          csv += `"${row["arumma"]}";`;
          csv += `"${row["quantita"].toString().replace(".", ",")}";\n`;
        });

        var universalBOM = "\uFEFF";
        var csvData = new Blob([universalBOM + csv], {
          type: "text/csv;charset=utf-8",
        });
        // var csvData = new Blob([csv], { type: "text/csv;charset=utf8" });
        this.file_CSV = URL.createObjectURL(csvData);

        this.dialog_CSV_loading = false;

        var d = new Date();
        this.date_export =
          d.getFullYear() +
          "" +
          (d.getMonth() + 1) +
          "" +
          d.getDate() +
          "" +
          d.getHours() +
          "" +
          d.getMinutes() +
          "" +
          d.getSeconds();

        this.itemsPerPage = itemsBuffer;
      });
    },
    range6Mesi(v) {
      //  Controllo che la data fine sia entro i 6 mesi rispetto alla data di inizio
      var data_inizio = new Date(this.filtro.data_inizio);
      var data_fine = new Date(this.filtro.data_fine);

      data_inizio.setMonth(data_inizio.getMonth() + 6);

      return data_fine < data_inizio ? true : this.$t("key655");
    },
  },
  watch: {
    filtro: {
      handler(new_val) {
        //  Salvo filtro nel session storage
        var session_filtro = Object.assign({}, this.filtro);
        var old_val = JSON.parse(sessionStorage.getItem("filtroMov"));
        sessionStorage.setItem("filtroMov", JSON.stringify(session_filtro));

        if (!old_val) {
          return;
        }

        this.$refs.formFiltro.validate();
        this.$nextTick(() => {
          if (!this.validFiltro) {
            if (new_val.admin_cliente != old_val.admin_cliente) {
              this.CambiaCodiceSelezionato().then(() => {
                this.$nextTick(() => {
                  this.loading = false;
                  return;
                });
              });
            }
            return;
          }

          if (
            new_val.data_inizio != old_val.data_inizio ||
            new_val.data_fine != old_val.data_fine ||
            new_val.admin_cliente != old_val.admin_cliente
          ) {
            if (new_val.admin_cliente != old_val.admin_cliente) {
              if (!new_val.admin_cliente) {
                this.items = [];
                return;
              }

              this.CambiaCodiceSelezionato().then(() => {
                this.getDataFromApi();
              });
            } else {
              this.getDataFromApi();
            }
          }
        });
      },
      deep: true,
    },
    search(val) {
      sessionStorage.setItem("searchMov", val);
    },
  },
};
</script>