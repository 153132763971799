import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import it from 'vuetify/lib/locale/it'
import en from 'vuetify/lib/locale/en'

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { it, en },
    current: 'en',
  },
  theme: {
    themes: {
      light: {
        primary: '#4EABDD',
        // secondary: '#424242', //  Uso il colore come sfondo neutro e per i bottoni "Annulla" o "Chiudi"
        // accent: '#424242' 
        //  error: '#da0000'
        background: '#fafafa'
      }
    }
  }
});


