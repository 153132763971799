<template>
  <v-container>
    <v-card>
      <v-card-title>
        <strong>Gestione Avvisi</strong>
        <v-btn
          fab
          dark
          small
          color="primary"
          @click="creaAvviso()"
          class="ml-2"
        >
          <v-icon dark>mdi-plus</v-icon>
        </v-btn>
      </v-card-title>
      <v-data-table
        :search="search"
        fixed-header
        height="calc(100dvh - 313px)"
        :headers="headers"
        :items="avvisi"
        :loading="loading"
        @click:row="updateRecord"
        sort-by="id"
        :sort-desc="true"
        :footer-props="{
          'items-per-page-options': [5, 10, 25, -1],
        }"
        :items-per-page="25"
        :must-sort="true"
        :no-data-text="$vuetify.noDataText"
      >
        <template v-slot:top>
          <div class="d-flex align-center">
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model="search"
                label="Cerca"
                prepend-inner-icon="mdi-magnify"
                clearable
              ></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="6" md="4" class="text-end">
              <v-btn @click="downloadCSV()" depressed color="primary">
                <span>
                  Esporta report
                  <v-icon right> mdi-download </v-icon>
                </span>
              </v-btn>
            </v-col>
          </div>
        </template>
        <template v-slot:[`item.dataInizio`]="{ item }">
          {{
            item.dataInizio != null &&
            item.dataInizio != undefined &&
            item.dataInizio != ""
              ? formatData(item.dataInizio, 0)
              : ""
          }}
        </template>
        <template v-slot:[`item.dataFine`]="{ item }">
          {{
            item.dataFine != null &&
            item.dataFine != undefined &&
            item.dataFine != ""
              ? formatData(item.dataFine, 0)
              : ""
          }}
        </template>
        <template v-slot:[`item.numAllegati`]="{ item }">
          <span :style="item.daLeggere ? 'font-weight: 600;' : ''">{{
            getNumAllegati(item)
          }}</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div style="width: 40px">
            <v-tooltip top v-if="item['btflfa'] != 0">
              <template v-slot:activator="{ on, attrs }">
                <v-icon small @click.stop="mostraAvviso(item)" v-on="on" v-blur>
                  mdi-newspaper-variant-outline
                </v-icon>
              </template>
              <span>Visualizza avviso</span>
            </v-tooltip>
            <v-tooltip top v-if="item['btflfa'] != 0">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  @click.stop="cancellaConferma(item.id)"
                  v-on="on"
                  v-blur
                >
                  mdi-delete
                </v-icon>
              </template>
              <span>Cancella avviso</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </v-card>
    <!-- POPUP AVVISI -->
    <v-dialog v-model="dialogAvvisi" max-width="900px" persistent>
      <v-card>
        <v-card-text class="pa-0 ma-0">
          <MostraAvviso :avviso="dialogAvviso" />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" depressed @click="chiudiAvviso()">
            {{ $t("key118") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- EXPORT DIALOG -->
    <v-dialog v-model="dialog_CSV" max-width="500px" persistent>
      <v-card>
        <v-card-title class="headline pt-4">Download CSV</v-card-title>
        <v-card-text>
          <div class="mt-4" v-if="!dialog_CSV_loading">
            <p style="display: inline">File .csv pronto!</p>
            <a
              :download="`Export_avvisi_visualizzati_${date_export}.csv`"
              :href="file_CSV"
              style="text-decoration: none; display: inline"
              class="ml-4"
            >
              <v-btn @click="dialog_CSV = false" color="primary">
                Download
                <v-icon right> mdi-download </v-icon>
              </v-btn>
            </a>
          </div>
          <div class="d-flex align-center flex-column mt-4" v-else>
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" depressed @click="dialog_CSV = false"
            >Chiudi</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DELETE DIALOG -->
    <v-dialog v-model="dialogDelete" max-width="500px" persistent>
      <v-card style="text-align: center">
        <v-card-title class="text-h5" style="justify-content: center">Attenzione</v-card-title>
        <v-card-text class="pb-0">
          <span class="black--text text-subtitle-1"
            >Sei sicuro di voler cancellare l'avviso'?</span
          >
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#A0A4A7"
            @click="dialogDelete = false"
            depressed
            style="color: white"
          >
            ANNULLA
          </v-btn>
          <v-btn
            color="primary"
            @click="CancellaAvviso()"
            depressed
            :loading="dialogDeleteLoading"
          >
            CONFERMA
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import MostraAvviso from "../components/MostraAvviso.vue";

export default {
  components: {
    MostraAvviso,
  },
  name: "GestioneAvvisiList",
  data: () => ({
    search: "",
    headers: [
      { text: "Id", value: "id" },
      { text: "Titolo", value: "titoloIt" },
      { text: "Data inizio", value: "dataInizio" },
      { text: "Data fine", value: "dataFine" },
      { text: "Allegati", value: "numAllegati" },
      { text: "Azioni", value: "actions", sortable: false },
    ],
    avvisi: [],
    loading: false,
    dialog_CSV: false,
    dialog_CSV_loading: false,
    date_export: null,
    file_CSV: null,
    reportAvvisi: [],
    dialogAvvisi: false,
    dialogAvviso: {},
    dialogDelete: false,
    avvisoDaEliminare: 0,
    dialogDeleteLoading: false,
  }),
  created() {
    this.$store.dispatch("ImpostaScollegamento");
  },
  mounted() {
    this.getAvvisi();
  },
  computed: {},
  methods: {
    getAvvisi() {
      this.loading = true;
      this.avvisi = [];
      this.axios
        .get(this.$store.state.servicePath + "/api/ArAvvisis/tutti")
        .then((res) => {
          this.avvisi = res.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    mostraAvviso(item) {
      this.dialogAvviso = Object.assign({}, item);
      this.dialogAvvisi = true;
    },
    chiudiAvviso() {
      this.dialogAvvisi = false;
    },
    getNumAllegati(item) {
      try {
        var n = 0;

        if (item.allegato1) {
          n++;
        }

        if (item.allegato2) {
          n++;
        }

        if (item.allegato3) {
          n++;
        }

        if (item.allegato4) {
          n++;
        }

        return n;
      } catch (e) {
        return 0;
      }
    },
    formatData(data, tipo) {
      var dateTime = data.split("T");
      var date = `${dateTime[0].substr(8, 2)}/${dateTime[0].substr(
        5,
        2
      )}/${dateTime[0].substr(0, 4)}`;
      if (tipo == 1) {
        return `${date} ${dateTime[1].substr(0, 5)}`;
      }
      return date;
    },
    creaAvviso() {
      this.$router.push({
        path: "/gestione-avvisi/0",
      });
    },
    updateRecord(item) {
      this.$router.push({
        path: `/gestione-avvisi/${item.id}`,
      });
    },
    cancellaConferma(id) {
      this.avvisoDaEliminare = id;
      this.dialogDelete = true;
    },
    CancellaAvviso() {
      this.dialogDeleteLoading = true;
      this.axios
        .put(
          `${this.$store.state.servicePath}/api/ArAvvisis/elimina/${this.avvisoDaEliminare}`
        )
        .then(() => {
          this.dialogDelete = false;
          this.dialogDeleteLoading = false;
          this.getAvvisi();
        })
        .catch((err) => {
          this.dialogDelete = false;
          this.dialogDeleteLoading = false;
          console.log(err);
        });
    },
    downloadCSV() {
      this.axios
        .get(this.$store.state.servicePath + "/api/ArAvvisis/report")
        .then((res) => {
          this.reportAvvisi = res.data;
          this.creaCSV();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    creaCSV() {
      this.dialog_CSV_loading = true;
      this.dialog_CSV = true;

      this.$nextTick(() => {
        var csv = "";
        csv += `"ID AVVISO";"TITOLO";"DATA INIZIO";"DATA FINE";"ACCETTAZIONE OBBLIGATORIA";"ID UTENTE";"CODICE UTENTE";"EMAIL UTENTE";"DATA/ORA AVVISO LETTO";\n`;

        this.reportAvvisi.forEach((row) => {
          csv += `"${row["id"]}";`;
          csv += `"${row["titoloIt"]}";`;
          csv += `"${this.formatData(row["dataInizio"], 0)}";`;
          csv += `"${
            row["dataFine"] != null && row["dataFine"] != ""
              ? this.formatData(row["dataFine"], 0)
              : ""
          }";`;
          csv += `"${row["accettazioneObbligatoria"]}";`;
          row["utenti"].forEach((el, i) => {
            if (i > 0) {
              csv += `;;;;;`;
            }
            csv += `"${el["id"]}";`;
            csv += `"'${el["codice"]}";`;
            csv += `"${el["email"]}";`;
            csv += `"${this.formatData(el["dataOraEvento"], 1)}";`;
            csv += "\n";
          });
        });

        var universalBOM = "\uFEFF";
        var csvData = new Blob([universalBOM + csv], {
          type: "text/csv;charset=utf-8",
        });
        // var csvData = new Blob([csv], { type: "text/csv;charset=utf8" });
        this.file_CSV = URL.createObjectURL(csvData);

        this.dialog_CSV_loading = false;

        var d = new Date();
        this.date_export =
          d.getFullYear() +
          "" +
          (d.getMonth() + 1) +
          "" +
          d.getDate() +
          "" +
          d.getHours() +
          "" +
          d.getMinutes() +
          "" +
          d.getSeconds();
      });
    },
  },
  watch: {},
};
</script>
